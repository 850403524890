.button {
    width: 222px;
    height: 49px;
    border-radius: 15px;
    border: 3px solid $green;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
    }

    &.active {
        border-color: $bg-blue;
        background-color: $bg-blue;
    }
}

.btn-toggle {
    width: 64px;
    height: 33px;
    border-radius: 100px;
    border: 1px solid #dedede;
    background-color: #bcbcbc;
    position: relative;
    cursor: pointer;

    .circle {
        width: 27px;
        height: 27px;
        background-color: #fff;
        border-radius: 50%;
        margin: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
    }

    &.active {
        border-color: #9fd2f1;
        background-color: #1d8cc3;

        .circle {
            margin-inline: auto 3px;
        }
    }
}
